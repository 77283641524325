
.suggestions{
    margin: 15px auto;
    width: 300px;
    background-color: floralwhite;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 15px 15px;
    padding: 20px;
}


.FigureOverlayWrap{
    margin: 15px auto;
    position: relative;
    height: 500px;
    width: 500px;   
}
.FigureOverlayWrap .icon{
    position: absolute;
    top: 30px;
    left: 50px;
    width: 275px;
    height: 275px;
    z-index: 100;   
}
.FigureOverlayWrap svg{
    position: absolute;
    display: block;
    width: 500px;
    height: 500px;
    border: 0px solid rgb(0, 0, 0);
    border-radius: 250px;
    box-shadow: rgba(255, 0, 212, 0.5) 0px 0px 15px 15px;
    
}

.FigureOverlayWrap .windIndex{
    position: absolute;
    top: 300px;
    left: 90px;
    width: 80px;
    height: 80px;
    z-index: 101;
    background: rgb(255, 255, 255);
    border-radius: 40px;
    color: rgb(0, 0, 0);
    border:rgb(0, 0, 0) dotted 3px;
    font-size: 55px;
}

.FigureOverlayWrap .person{
    position: absolute;
    top: 160px;
    left: 140px;
    width: 330px;
    height: 330px;
    z-index: 102;
}

.FigureOverlayWrap .rightinformation-first{
    position: absolute;
    top: 100px;
    left: 315px;
    width: 100px;
    height: 40px;
    z-index: 101;
    text-align: center;
    color: black;
    display: block;
    opacity: 0.5;
    background-color: rgb(255, 255, 255);
}

.FigureOverlayWrap .rightinformation{
    position: absolute;
    top: 140px;
    left: 315px;
    width: 150px;
    height: 40px;
    z-index: 101;
    text-align: right;
    display: block;
    opacity: 0.5;
    background-color: rgb(255, 255, 255);
    color: black;
}

.FigureOverlayWrap .leftinformation{
    position: absolute;
    z-index: 101;
    display: block;
    width: 110px;
    height: 40px;
    opacity: 0.5;
    background-color: rgb(255, 255, 255);
    color: black;
    top: 395px;
    left: 100px;

}

@media only screen and (max-width: 600px) {
    .suggestions{
        margin: 25px auto;
        padding: 20px;
        width: 200px;
        background-color: floralwhite;
        color: black;
        box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 15px 15px;
    }
    .FigureOverlayWrap{
        width: 300px;
        height: 300px;
    }

    .FigureOverlayWrap .icon{
        position: absolute;
        top: 30px;
        left: 50px;
        width: 125px;
        height: 125px;
        z-index: 100;   
    }
    .FigureOverlayWrap svg{
        position: absolute;
        display: block;
        width: 300px;
        height: 300px;
    }
    
    .FigureOverlayWrap .person{
        position: absolute;
        top: 110px;
        left: 85px;
        width: 175px;
        height: 175px;
        z-index: 102;
    }
    
    .FigureOverlayWrap .rightinformation-first{
        position: absolute;
        top: 65px;
        left: 180px;
        width: 70px;
        height: 35px;
        z-index: 101;
        text-align: center;

    }
    
    .FigureOverlayWrap .rightinformation{
        position: absolute;
        top: 100px;
        left: 180px;
        width: 100px;
        height: 27px;
        z-index: 101;
        text-align: right;

        
    }

    .FigureOverlayWrap .leftinformation{
        position: absolute;
        z-index: 101;
        display: block;
        width: 75px;
        height: 27px;
        top: 210px;
        left: 35px;
    
    }

    .FigureOverlayWrap .windIndex{
        font-size:30px;
        text-align: center;
        top: 155px;
        left: 35px;
        width: 40px;
        height: 40px;
        z-index: 101;
        background: rgb(255, 255, 255);
        border-radius: 20px;
        color: rgb(0, 0, 0);
        border:rgb(0, 0, 0) dotted 3px;
    }
}