
.presentContent{
    flex-wrap: wrap;
    flex-direction: column;
    flex-shrink: 2;
    display: flex;
    align-content: center;
    margin: 0 auto;
    max-width: 60%;
}

.clock{
    align-self: center;
}

.clockExplained{
    width: 421px;
    margin: 20px 0px 15px 0px;
}

@media only screen and (max-width: 600px){
    .clockExplained{
        width: 310px;
    }
}
