.content{
    min-height:100vh;
    width: 100;
    margin: 40px auto 40px auto;

}
.website{
    text-align: center;
    font-size: calc(10px + 2vmin);
    color: white;

}