
.Time-Teller-Backgorund{
    background-color: antiquewhite;
    flex-wrap: initial;
    flex-direction: row;
    display: flex;
    flex-grow: 2;

    padding: 3mm;
    justify-content: left;
    height: 290px;
    width: 450px;

  }
  
  .CircleOn{
    height: 60px;
    width: 60px;
    background-color: rgb(0, 132, 255);
    border-radius: 50%;
    margin: 2mm;
    background: radial-gradient(circle at 60% 110%, #feffff, #97cdff 10%, #09487c 80%, #011c35 100%);
  }
  
  .CircleOff{
    height: 60px;
    width: 60px;
    background-color: rgb(248, 241, 231);
    border-radius: 50%;
    margin: 2mm;
    background: radial-gradient(circle at 60% 110%, #f1f3dba2, #fce7d59a 10%, #ad996c8a 80%, #472f0b3b 100%);
  }

  @media only screen and (max-width: 600px){
    .Time-Teller-Backgorund{
      background-color: antiquewhite;
      flex-wrap: initial;
      flex-direction: row;
      display: flex;
      flex-grow: 2;
  
      padding: 3mm;
      justify-content: left;
      height: 190px;
      width: 310px;
      top: calc(50% - 200px);
      left: calc(50% - 200px);
    }
    
    .CircleOn{
      height: 37px;
      width: 37px;
      background-color: rgb(0, 132, 255);
      border-radius: 50%;
      margin: 2mm;
      background: radial-gradient(circle at 60% 110%, #feffff, #97cdff 10%, #09487c 80%, #011c35 100%);
    }
    
    .CircleOff{
      height: 37px;
      width: 37px;
      background-color: rgb(248, 241, 231);
      border-radius: 50%;
      margin: 2mm;
      background: radial-gradient(circle at 60% 110%, #f1f3dba2, #fce7d59a 10%, #ad996c8a 80%, #472f0b3b 100%);
    }
  }